import React, { useEffect, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import VideoCard from "../Atomics/VideoCard/VideoCard";
import { useVideos } from '../../VideoContext';

const EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours

export default function VideoBlockSport({ searchTerm }) {
  const { videoData2 } = useVideos();
  const [channels, setChannels] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
const checkUrl = async (url) => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // Timeout after 5 seconds

    const response = await fetch(url, { method: 'HEAD', signal: controller.signal });
    clearTimeout(timeoutId);

    return response.ok;
  } catch (error) {
    return false;
  }
};
  const fetchChannelsByCategory = async (categoryId) => {
    try {
      const channelsResponse = await fetch('https://iptv-org.github.io/api/channels.json');
      const channels = await channelsResponse.json();

      const streamsResponse = await fetch('https://iptv-org.github.io/api/streams.json');
      const streams = await streamsResponse.json();

      const filteredChannels = channels.filter(channel => 
        channel.categories && channel.categories.includes(categoryId)
      );
      const validChannels = [];
      for (const channel of filteredChannels) {
        const stream = streams.find(s => s.channel === channel.id);
        if (stream && stream.url) {
          validChannels.push({
            imgSrc: channel.logo || 'default-logo.png', // Default logo if none available
            time: "DIRECT",
            videoTitle: channel.name,
            videoCategory: channel.categories.join(', '),
            views: "Unknown", // Replace with real data if available
            timeAgo: "Unknown", // Replace with real data if available
            videoUrl: stream.url,
            verified: true
          });
  
          if (validChannels.length >= 100) {
            break;
          }
        }
      }
     

      return validChannels.reverse();
    } catch (error) {
      console.error('Error fetching channels or streams:', error);
      return [];
    }
  };
  const fetchChannelsByCountry = async (country) => {
    try {
      const channelsResponse = await fetch('https://iptv-org.github.io/api/channels.json');
      const channels = await channelsResponse.json();
  
      const streamsResponse = await fetch('https://iptv-org.github.io/api/streams.json');
      const streams = await streamsResponse.json();
  
      const filteredChannels = channels.filter(channel =>
        channel.country && channel.country.toLowerCase() === country.toLowerCase()
      );
  
      const validChannels = [];
      for (const channel of filteredChannels) {
        const stream = streams.find(s => s.channel === channel.id);
        if (stream && stream.url) {
          validChannels.push({
            imgSrc: channel.logo || 'default-logo.png', // Default logo if none available
            time: "DIRECT",
            videoTitle: channel.name,
            videoCategory: channel.categories.join(', '),
            views: "Unknown", // Replace with real data if available
            timeAgo: "Unknown", // Replace with real data if available
            videoUrl: stream.url,
            verified: true
          });
  
          if (validChannels.length >= 100) {
            break;
          }
        }
      }
  
      return validChannels.reverse();
    } catch (error) {
      console.error('Error fetching channels or streams:', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('meme');
      if (storedData) {
        const { channels, timestamp } = JSON.parse(storedData);
        const now = new Date().getTime();

        if (now - timestamp < EXPIRATION_TIME) {
          setChannels(channels);
          setDataLoaded(true);
          setIsLoading(false);
          return;
        }
      }

      if (!dataLoaded) {
        const sportsChannels = await fetchChannelsByCategory('sports');
        console.log('Fetched sports channels:', sportsChannels);
        setChannels(sportsChannels);
        localStorage.setItem('meme', JSON.stringify({ channels: sportsChannels, timestamp: new Date().getTime() }));
        setDataLoaded(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dataLoaded]);

  const filteredVideos = videoData2.filter(video =>
    video.videoTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="video-block section-padding">
      <Row>
        <Col md={12}>
          <SectionHeader heading="Chaines disponibles" />
        </Col>
        {isLoading ? (
          <Col md={12}>
            <div className="loading">Chargement en cours...</div>
          </Col>
        ) : (
          channels.map((video, index) => (
            <Col key={index} xl={3} sm={6} className="mb-3">
              <VideoCard
                imgSrc={video.imgSrc}
                time={video.time}
                videoTitle={video.videoTitle}
                videoCategory={video.videoCategory}
                views={video.views}
                timeAgo={video.timeAgo}
                videoUrl={video.videoUrl}
                verified={video.verified}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
}
