import React, { useState, useEffect } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import VideoCard from "../Atomics/VideoCard/VideoCard";
import { useVideos } from '../../VideoContext';
import CountryDropdown from './paysSelect';

const checkUrl = async (url) => {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // Timeout after 5 seconds

    const response = await fetch(url, { method: 'HEAD', signal: controller.signal });
    clearTimeout(timeoutId);

    return response.ok;
  } catch (error) {
    return false;
  }
};

const fetchChannelsByCountry = async (country) => {
  try {
    const channelsResponse = await fetch('https://iptv-org.github.io/api/channels.json');
    const channels = await channelsResponse.json();

    const streamsResponse = await fetch('https://iptv-org.github.io/api/streams.json');
    const streams = await streamsResponse.json();

    const filteredChannels = channels.filter(channel =>
      channel.country && channel.country.toLowerCase() === country.toLowerCase()
    );

    const validChannels = await Promise.all(filteredChannels.map(async (channel) => {
      const stream = streams.find(s => s.channel === channel.id);
      if (stream && stream.url) {
        return {
          imgSrc: channel.logo || 'default-logo.png', // Default logo if none available
          time: "DIRECT",
          videoTitle: channel.name,
          videoCategory: channel.categories.join(', '),
          views: "Unknown", // Replace with real data if available
          timeAgo: "Unknown", // Replace with real data if available
          videoUrl: stream.url,
          verified: true
        };
      }
      return null;
    }));

    return validChannels.filter(channel => channel !== null).reverse();
  } catch (error) {
    console.error('Error fetching channels or streams:', error);
    return [];
  }
};

export default function VideoBlock({ searchTerm }) {
  const { videoData1 } = useVideos();
  const [filteredVideos, setFilteredVideos] = useState(videoData1);
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (country) {
      setLoading(true);
      fetchChannelsByCountry(country).then(videos => {
        setFilteredVideos(videos);
        setLoading(false);
      });
    } else {
      setLoading(true);

      fetchChannelsByCountry('SN').then(videos => {
        setFilteredVideos(videos);
        setLoading(false);
      });
    }
  }, [country, videoData1]);

  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  const filteredSearchVideos = filteredVideos.filter(video =>
    video.videoTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="video-block section-padding">
      <Row>
        <Col md={12}>
          <CountryDropdown onCountrySelect={handleCountrySelect} />
          <SectionHeader heading="Chaines disponibles" />
        </Col>
        {loading ? (
          <Col md={12} className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        ) : (
          filteredSearchVideos.map((video, index) => (
            <Col key={index} xl={3} sm={6} className="mb-3">
              <VideoCard
                imgSrc={video.imgSrc}
                time={video.time}
                videoTitle={video.videoTitle}
                videoCategory={video.videoCategory}
                views={video.views}
                timeAgo={video.timeAgo}
                videoUrl={video.videoUrl}
                verified={video.verified}
              />
            </Col>
          ))
        )}
      </Row>
    </div>
  );
}
