import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import VideoCardList from "./VideoCardList";
import SingleVideoLeft from "./SingleVideoLeft";
import { useVideos } from '../../VideoContext';
import "./VideoPage.css";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: 'auto',
        fluid: true, // Enables responsive mode
        sources: [{
          src: src,
          type: 'application/x-mpegURL'
        }]
      });

      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [src]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.src({ src, type: 'application/x-mpegURL' });
      playerRef.current.load();
    }
  }, [src]);

  return (
    <div data-vjs-player className="video-player-wrapper">
      <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" />
    </div>
  );
};

const VideoPage = () => {
  const location = useLocation();
  const initialVideoUrl = location.state?.videoUrl || '';
  const initialVideoImg = location.state?.videoImg || '';
  const initialVideoTitle = location.state?.videoTitle || '';
  const initialVideoCategory = location.state?.videoCategory || '';

  const [currentVideoUrl, setCurrentVideoUrl] = useState(initialVideoUrl);
  const [currentVideoImg, setCurrentVideoImg] = useState(initialVideoImg);
  const [currentVideoTitle, setCurrentVideoTitle] = useState(initialVideoTitle);
  const [currentVideoCategory, setCurrentVideoCategory] = useState(initialVideoCategory);
  const [isFallback, setIsFallback] = useState(false);
  const { videoData1 } = useVideos();

  const fallbackUrl = 'https://ncdn-live-bfm.pfd.sfr.net/shls/LIVE$BFM_BUSINESS/index.m3u8?end=END&start=LIVE';

  const checkVideoUrl = async (videoUrl) => {
    try {
      const response = await fetch(videoUrl, { method: 'HEAD' });

      if (!response.ok) {
        setCurrentVideoUrl(fallbackUrl);
        setIsFallback(true);
      } else {
        setCurrentVideoUrl(videoUrl);
        setIsFallback(false);
      }
    } catch (error) {
      setCurrentVideoUrl(fallbackUrl);
      setIsFallback(true);
    }
  };

  useEffect(() => {
    if (currentVideoCategory.toLowerCase() !== 'sports') {
      checkVideoUrl(initialVideoUrl);
    }
  }, [initialVideoUrl, currentVideoCategory]);

  const handleVideoCardClick = (videoUrl, videoImg, videoTitle, videoCategory) => {
    setCurrentVideoUrl(videoUrl);
    setCurrentVideoImg(videoImg);
    setCurrentVideoTitle(videoTitle);
    setCurrentVideoCategory(videoCategory);
    setIsFallback(false);
    if (videoCategory.toLowerCase() !== 'sports') {
      checkVideoUrl(videoUrl);
    }
  };

  useEffect(() => {
    console.log('Current Video URL:', currentVideoUrl);
    console.log('Current Video Img:', currentVideoImg);
    console.log('Current Video Title:', currentVideoTitle);
    console.log('Current Video Category:', currentVideoCategory);
    console.log('Is Fallback:', isFallback);
  }, [currentVideoUrl, currentVideoImg, currentVideoTitle, currentVideoCategory, isFallback]);

  useEffect(() => {
  }, []);

  return (
    <>
      <ContentWrapper>
        <Container fluid>
          <div className="video-block-right-list section-padding">
            <Row className="mb-4">
              <Col md={8} className="mb-4 mb-md-0">
                <div className="single-video">
                  <div className="player-wrapper">
                    <VideoPlayer src={currentVideoUrl} />
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="video-slider-right-list">
                {/*  {videoData1.map((video, index) => (
                    <VideoCardList
                      key={index}
                      imgSrc={video.imgSrc}
                      time={video.time}
                      videoTitle={video.videoTitle}
                      videoCategory={video.videoCategory}
                      views={video.views}
                      timeAgo={video.timeAgo}
                      videoUrl={video.videoUrl}
                      verified={video.verified}
                    />
                  ))}*/}
                </div>
              </Col>
            </Row>
          </div>

          <div className="video-block section-padding">
            <Row>
              <Col md={8}>
                <SingleVideoLeft videoImg={currentVideoImg} videoTitle={currentVideoTitle} />
              </Col>

              <Col md={4}>
              </Col>
            </Row>
          </div>
        </Container>
        <ThinFooter />
      </ContentWrapper>
    </>
  );
};

export default VideoPage;
