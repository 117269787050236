import VideoTitle from "./VideoTitle";
import AuthorBox from "./AuthorBox";
import VideoDescription from "./VideoDescription";
import CommentBox from "./CommentBox";

const SingleVideoLeft = ({videoImg,videoTitle}) => {
	return (
		<>
			<div className="single-video-left">
				<VideoTitle
					title="EN DIRECT ."
					views="2,729,347"
				/>

				<AuthorBox
					subscriberCount="1.4M"
					imgSrc={videoImg}
					channelName={videoTitle}
					verified
					publishedOn="Aug 10, 2020"
				/>


=			</div>
		</>
	);
};

export default SingleVideoLeft;
