import React, { useState } from 'react';
import Select, { components } from 'react-select';

const countryOptions = [
 
  { value: 'CI', label: 'Côte d\'Ivoire', flag: 'https://flagcdn.com/ci.svg' },
  { value: 'SN', label: 'Senegal	', flag: 'https://flagcdn.com/sn.svg' },

  { value: 'NG', label: 'Nigeria', flag: 'https://flagcdn.com/ng.svg' },

  { value: 'AO', label: 'Angola', flag: 'https://flagcdn.com/ao.svg' },
  { value: 'ML', label: 'Mali', flag: 'https://flagcdn.com/ml.svg' },
  { value: 'GH', label: 'Ghana', flag: 'https://flagcdn.com/gh.svg' },
  { value: 'TG', label: 'Togo', flag: 'https://flagcdn.com/tg.svg' },
  { value: 'GA', label: 'Gabon', flag: 'https://flagcdn.com/ga.svg' },
  { value: 'MA', label: 'Morocco	', flag: 'https://flagcdn.com/ma.svg' },


  
];


const customOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={props.data.flag} alt="" style={{ width: '20px', marginRight: '10px' }} />
        {props.data.label}
      </div>
    </components.Option>
  );
};

const CountryDropdown = ({ onCountrySelect }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onCountrySelect(selectedOption ? selectedOption.value : null);
  };

  return (
    <div style={{ width: '100%', margin: '0 auto', padding: '20px' }}>
      <Select
        value={selectedCountry}
        onChange={handleCountryChange}
        options={countryOptions}
        placeholder="Selectionner un pays"
        isClearable
        isSearchable={false}
        components={{ Option: customOption }}
      />
    </div>
  );
};

export default CountryDropdown;
