import "./Homepage.css";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import TopMobileSearch from "./TopMobileSearch";
import TopCategory from "./TopCategory";
import VideoBlockSport from "./SportChaine";
import PopularChannels from "./PopularChannels";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

const Sportchaine = () => {
	const [searchTerm, setSearchTerm] = useState('');

	return (
		<>
			<ContentWrapper>
				<Container fluid className="pb-0">
					<TopMobileSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					<hr />
					<VideoBlockSport searchTerm={searchTerm} />
					<hr className="mt-0" />
				</Container>

			</ContentWrapper>
		</>
	);
};

export default Sportchaine;
