import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import "./VideoCard.css";
import {
  VerifiedTooltip,
  UnverifiedTooltip,
} from "../CustomCheckTooltips/CustomCheckTooltips";

function VideoCard({
  iconHref = "#",
  imgHref = "#",
  imgSrc,
  imgAlt = "",
  time = "-:-",
  videoTitle = "#",
  videoTitleHref,
  views,
  timeAgo,
  videoCategory,
  verified = null,
  videoUrl = "#",
}) {
  const history = useHistory();

  const handleCardClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/video-page',
      state: { videoUrl: videoUrl, videoImg: imgSrc, videoTitle: videoTitle,videoCategory:videoCategory }
    });
  };

  return (
    <div className="video-card" onClick={handleCardClick} style={{ cursor: "pointer" }}>
      <div className="video-card-image">
        <a className="play-icon" href={videoUrl} onClick={handleCardClick}>
          <FontAwesomeIcon icon={faPlayCircle} />
        </a>
        <a href={videoUrl} onClick={handleCardClick}>
          <img
            className="img-fixed-size"
            src={imgSrc}
            alt={imgAlt}
            loading="lazy"
            width="320" // specify a fixed width for optimization
            height="180" // specify a fixed height for optimization
          />
        </a>
        <div className="time">{time}</div>
      </div>
      <div className="video-card-body">
        <div className="video-title">
          <a href={videoTitleHref}>{videoTitle}</a>
        </div>
        <div
          className={
            verified
              ? "video-page text-success-custom"
              : "video-page text-danger-custom"
          }
        >
          {videoCategory}{" "}
          {verified ? <VerifiedTooltip /> : <UnverifiedTooltip />}
        </div>
        <div className="video-view">
          {views} views &nbsp;
          <FontAwesomeIcon icon={faCalendarAlt} /> {timeAgo} ago
        </div>
      </div>
    </div>
  );
}

export default VideoCard;
