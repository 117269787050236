import WatchedVideoCard from "../Atomics/WatchedVideoCard/WatchedVideoCard";

export default function WatchedVideos() {
	return (
		<>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
			<WatchedVideoCard
				imgSrc="img/v1.png"
				time="3:50"
				videoTitle="There are many variations of passages of Lorem"
				views="1.8M"
				timeAgo="11 Months"
				videoCategory="Education"
				progress="50"
				progressTime="1:40"
				verified
			/>
		</>
	);
}
