import React, { createContext, useContext } from 'react';

const VideoContext = createContext();

const videoData1 = [
  {
    imgSrc: "img/tfm.png",
    time: "DIRECT",
    videoTitle: "TFM SENEGAL LIVE",
    videoCategory: "Divertissement",
    views: "2.5M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/tfm/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sentv.png",
    time: "DIRECT",
    videoTitle: "SENTV SENEGAL LIVE",
    videoCategory: "Divertissement",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/sentv/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/rts.jpg",
    time: "DIRECT",
    videoTitle: "RTS SENEGAL LIVE",
    videoCategory: "Education",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/rts1/playlist.m3u8"
  },
  {
    imgSrc: "img/2stv.jpg",
    time: "DIRECT",
    videoTitle: "2stv SENEGAL LIVE",
    videoCategory: "Education",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/2stv/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/f24.png",
    time: "DIRECT",
    videoTitle: "FRANCE 24",
    videoCategory: "Education",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ythls.armelin.one/channel/UCCCPCZNChQdGa9EkATeye4g.m3u8",
    verified: true
  },

  {
    imgSrc: "img/7tv.jpg",
    time: "DIRECT",
    videoTitle: "7TV SENEGAL LIVE",
    videoCategory: "Education",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/septtv/playlist.m3u8"
  },
  {
    imgSrc: "img/walftv.jpg",
    time: "DIRECT",
    videoTitle: "WALFTV SENEGAL LIVE",
    videoCategory: "Education",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/walftv/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/mouride.jpg",
    time: "DIRECT",
    videoTitle: "Mouride TV",
    videoCategory: "Religion",
    views: "1.8M",
    timeAgo: "11 months"
  },
  {
    imgSrc: "img/bein.png",
    time: "DIRECT",
    videoTitle: "BEIN SPORT 1",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/bein1/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/canal.png",
    time: "DIRECT",
    videoTitle: "CANAL+ FOOT",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/canalfoot/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/bein.png",
    time: "DIRECT",
    videoTitle: "RMC SPORT",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/rmcsport/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/2a.jpg",
    time: "DIRECT",
    videoTitle: "2AI",
    videoCategory: "Divertissement",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ythls.armelin.one/channel/UCh57LRfcD3Z4TK6WrzL39GA.m3u8",
    verified: true
  },
  {
    imgSrc: "img/ahlou.png",
    time: "DIRECT",
    videoTitle: "Ahlou Mouride",
    videoCategory: "Religion",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ythls.armelin.one/channel/UCuFSrdYq5td__h886cSTmVw.m3u8",
    verified: true
  },
  {
    imgSrc: "img/ahlou.png",
    time: "DIRECT",
    videoTitle: "AL FAYDA",
    videoCategory: "Religion",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ythls.armelin.one/channel/UCuFSrdYq5td__h886cSTmVw.m3u8",
    verified: true
  },
  {
    imgSrc: "img/a24.jpg",
    time: "DIRECT",
    videoTitle: "AFRICA NEWS",
    videoCategory: "News",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://edge12.vedge.infomaniak.com/livecast/ik:africa24/manifest.m3u8",
    verified: true
  },
  {
    imgSrc: "img/al.png",
    time: "DIRECT",
    videoTitle: "Al Jazeera Arabic",
    videoCategory: "News",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://live-hls-v3-aja.getaj.net/AJA-V3/index.m3u8",
    verified: true
  },
  {
    imgSrc: "img/bfm.png",
    time: "DIRECT",
    videoTitle: "BFM TV",
    videoCategory: "News",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ncdn-live-bfm.pfd.sfr.net/shls/LIVE$BFM_BUSINESS/index.m3u8?end=END&start=LIVE",
    verified: true
  },
  {
    imgSrc: "img/euro.jpg",
    time: "DIRECT",
    videoTitle: "Euronews TV",
    videoCategory: "News",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://euronews-euronews-french-3-us.plex.wurl.tv/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/isti.jpg",
    time: "DIRECT",
    videoTitle: "Istikhama TV",
    videoCategory: "Religion",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://ythls.armelin.one/channel/UCIpj2Vtw2GkXEfhevrAbkCQ.m3u8",
    verified: true
  },
  {
    imgSrc: "img/dora.jpg",
    time: "DIRECT",
    videoTitle: "DORA TV",
    videoCategory: "Dessin Animes",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "http://cfd-v4-service-channel-stitcher-use1-1.prd.pluto.tv/stitch/hls/channel/5f8ecb9524419b0007365a1c/master.m3u8?appName=web&appVersion=unknown&clientTime=0&deviceDNT=0&deviceId=8e050350-1f2c-11ef-86d8-5d587df108c6&deviceMake=Chrome&deviceModel=web&deviceType=web&deviceVersion=unknown&includeExtendedEvents=false&serverSideAds=false&sid=c938e920-2111-4877-9e36-97d7a0a0d005",
    verified: true
  },
  // Add other video objects here...
];
const videoData2 = [
  {
    imgSrc: "img/bein.png",
    time: "DIRECT",
    videoTitle: "BEIN SPORT 1",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/bein1/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/canal.png",
    time: "DIRECT",
    videoTitle: "CANAL+ FOOT",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/canalfoot/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/bein.png",
    time: "DIRECT",
    videoTitle: "RMC SPORT",
    videoCategory: "Sports",
    views: "1.8M",
    timeAgo: "11 months",
    videoUrl: "https://cloudvider.leatdev.com/live/rmcsport/playlist.m3u8",
    verified: true
  },
  {
    imgSrc: "img/a_sport.png",
    time: "DIRECT",
    videoTitle: "A Spor (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/a_sport_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/ovacion_tv.png",
    time: "DIRECT",
    videoTitle: "Ovacion TV (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/ovacion_tv_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pac_12_insider.png",
    time: "DIRECT",
    videoTitle: "Pac 12 Insider (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pac_12_insider_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pbr_ridepass.png",
    time: "DIRECT",
    videoTitle: "PBR RidePass",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pbr_ridepass.m3u8",
    verified: true
  },
  {
    imgSrc: "img/persiana_sport.png",
    time: "DIRECT",
    videoTitle: "Persiana Sport",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/persiana_sport.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pga_tour.png",
    time: "DIRECT",
    videoTitle: "PGA Tour (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pga_tour_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/players_tv.png",
    time: "DIRECT",
    videoTitle: "Players TV (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/players_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_deportes.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Deportes (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_deportes_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_extreme.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Extréme",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_extreme.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_motor.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Motor",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_motor.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_sport_italy.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Sport Italy (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_sport_italy_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_sports.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Sports",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_sports.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pluto_tv_sports_720p.png",
    time: "DIRECT",
    videoTitle: "Pluto TV Sports (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pluto_tv_sports_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/poker_tv.png",
    time: "DIRECT",
    videoTitle: "Poker TV (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/poker_tv_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/pokergo.png",
    time: "DIRECT",
    videoTitle: "PokerGo",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/pokergo.m3u8",
    verified: true
  },
  {
    imgSrc: "img/ptv_sports.png",
    time: "DIRECT",
    videoTitle: "PTV Sports",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/ptv_sports.m3u8",
    verified: true
  },
  {
    imgSrc: "img/qazsport.png",
    time: "DIRECT",
    videoTitle: "QazSport (480p) [Geo-blocked]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/qazsport_480p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/racing_america.png",
    time: "DIRECT",
    videoTitle: "Racing America (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/racing_america_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/racing_com.png",
    time: "DIRECT",
    videoTitle: "Racing.com (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/racing_com_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/real_madrid.png",
    time: "DIRECT",
    videoTitle: "Real Madrid",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/real_madrid.m3u8",
    verified: true
  },
  {
    imgSrc: "img/realitatea_sportiva.png",
    time: "DIRECT",
    videoTitle: "Realitatea Sportivă (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/realitatea_sportiva_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/red_bull_tv.png",
    time: "DIRECT",
    videoTitle: "Red Bull TV (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/red_bull_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/rta_sport.png",
    time: "DIRECT",
    videoTitle: "RTA Sport (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/rta_sport_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/san_marino_rtv_sport.png",
    time: "DIRECT",
    videoTitle: "San Marino RTV Sport",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/san_marino_rtv_sport.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sharjah_sports_tv.png",
    time: "DIRECT",
    videoTitle: "Sharjah Sports TV (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sharjah_sports_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/ski_tv.png",
    time: "DIRECT",
    videoTitle: "Ski TV (1080p) [Not 2]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/ski_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sky_racing_1.png",
    time: "DIRECT",
    videoTitle: "Sky Racing 1 (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sky_racing_1_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sky_racing_2.png",
    time: "DIRECT",
    videoTitle: "Sky Racing 2 (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sky_racing_2_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sky_thoroughbred_central.png",
    time: "DIRECT",
    videoTitle: "Sky Thoroughbred Central",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sky_thoroughbred_central.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sony_six_hd.png",
    time: "DIRECT",
    videoTitle: "Sony Six HD (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sony_six_hd_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sony_sports_ten_1.png",
    time: "DIRECT",
    videoTitle: "Sony Sports Ten 1 (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sony_sports_ten_1_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sony_sports_ten_2_hd.png",
    time: "DIRECT",
    videoTitle: "Sony Sports TEN 2 HD",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sony_sports_ten_2_hd.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sony_sports_ten_3.png",
    time: "DIRECT",
    videoTitle: "Sony Sports Ten 3 (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sony_sports_ten_3_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sony_sports_ten_4.png",
    time: "DIRECT",
    videoTitle: "Sony Sports Ten 4 (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sony_sports_ten_4_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sos_kanal_plus.png",
    time: "DIRECT",
    videoTitle: "SOS Kanal Plus (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sos_kanal_plus_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/sport_en_france.png",
    time: "DIRECT",
    videoTitle: "Sport En France (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/sport_en_france_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/abu_dhabi_sports_1.png",
    time: "DIRECT",
    videoTitle: "Abu Dhabi Sports 1",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/abu_dhabi_sports_1.m3u8",
    verified: true
  },
  {
    imgSrc: "img/abu_dhabi_sports_2.png",
    time: "DIRECT",
    videoTitle: "Abu Dhabi Sports 2",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/abu_dhabi_sports_2.m3u8",
    verified: true
  },
  {
    imgSrc: "img/accdn.png",
    time: "DIRECT",
    videoTitle: "ACCDN (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/accdn_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/aio_sport_2.png",
    time: "DIRECT",
    videoTitle: "Aio Sport 2 [Geo-blocked]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/aio_sport_2.m3u8",
    verified: true
  },
  {
    imgSrc: "img/all_sports.png",
    time: "DIRECT",
    videoTitle: "All Sports (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/all_sports_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/atg.png",
    time: "DIRECT",
    videoTitle: "ATG (432p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/atg_432p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/awapa_sports_tv.png",
    time: "DIRECT",
    videoTitle: "Awapa Sports TV (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/awapa_sports_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/b18_box.png",
    time: "DIRECT",
    videoTitle: "B18 Box (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/b18_box_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/caf_tv.png",
    time: "DIRECT",
    videoTitle: "CAF TV (1080p) [Not 2]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/caf_tv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/campuslore.png",
    time: "DIRECT",
    videoTitle: "CampusLore (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/campuslore_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/canal_11.png",
    time: "DIRECT",
    videoTitle: "Canal 11 (1080p) [Not 2]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/canal_11_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/canal_do_inter.png",
    time: "DIRECT",
    videoTitle: "Canal do Inter (720p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/canal_do_inter_720p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/canal_plus_sport.png",
    time: "DIRECT",
    videoTitle: "Canal+ Sport",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/canal_plus_sport.m3u8",
    verified: true
  },
  {
    imgSrc: "img/cazetv.png",
    time: "DIRECT",
    videoTitle: "CazéTV (1080p) [Geo-blocked]",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/cazetv_1080p.m3u8",
    verified: true
  },
  {
    imgSrc: "img/cbs_sports_golazo.png",
    time: "DIRECT",
    videoTitle: "CBS Sports Golazo Net...",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/cbs_sports_golazo.m3u8",
    verified: true
  },
  {
    imgSrc: "img/cbs_sports_hq.png",
    time: "DIRECT",
    videoTitle: "CBS Sports HQ",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/cbs_sports_hq.m3u8",
    verified: true
  },
  {
    imgSrc: "img/cctv5_plus.png",
    time: "DIRECT",
    videoTitle: "CCTV5+",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/cctv5_plus.m3u8",
    verified: true
  },
  {
    imgSrc: "img/cctv5.png",
    time: "DIRECT",
    videoTitle: "CCTV-5 (1080p)",
    videoCategory: "Sports",
    views: "Unknown",
    timeAgo: "Unknown",
    videoUrl: "url/to/cctv5_1080p.m3u8",
    verified: true
  }
];
export const VideoProvider = ({ children }) => {
  return (
    <VideoContext.Provider value={{ videoData1, videoData2 }}>
      {children}
    </VideoContext.Provider>
  );
};
export const useVideos = () => {
  return useContext(VideoContext);
};
